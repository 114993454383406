// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDppcvXpezke8metZid8g1Nz-XlIq-qILc",
  authDomain: "stellardepay.firebaseapp.com",
  projectId: "stellardepay",
  storageBucket: "stellardepay.appspot.com",
  messagingSenderId: "1072660348023",
  appId: "1:1072660348023:web:e7e959ac6fb27010269a72",
  measurementId: "G-VSBSTHJ5M2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;