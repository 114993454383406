import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';


export default function PayOut({setData2}) {

    const[amount, setAmount] = React.useState();
    const[email, setEmail] = React.useState();
    React.useEffect(() => {
         setData2({
            amount: amount,
            email: email
         })
    }, [email, amount]);

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Send to Paypal
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                        Amount
                    </Typography>
                    <TextField
                        required
                        id="amt"
                        name="amt"
                        label="min $100"
                        fullWidth
                        variant="filled"
                        size= "small"
                        onChange ={(e)=>setAmount(e.target.value)}
                    />         
                     <Typography gutterBottom>Transaction fees is 1%</Typography>
                      <p style={{fontSize: 12, color: " 	#000080"}}>minimum amount is $100 and maximum of $10000</p>
                </Grid>
                <Grid item container direction="column" xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                        Reciever Address
                    </Typography>
                    <Grid container>

                        <React.Fragment >
                            <Grid item xs={12}>
                                <TextField
                        required
                        id="amt"
                        name="amt"
                        label="email address"
                        fullWidth
                        variant="filled"
                        size= "small"
                       onChange ={(e)=>setEmail(e.target.value)}

                    /> 
                            </Grid>
                            <Grid item xs={12}>
                                <Typography gutterBottom>More PayOut Methods <br/> Comming Soon  </Typography>
                            </Grid>
                        </React.Fragment>

                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

