import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function LoadCard({ setData1 }) {
    const [cardName, setCardName] = React.useState();
    const [cardNumber, setCardNumber] = React.useState();
    const [expDate, setExpDate] = React.useState();
    const [cvv, setCvv] = React.useState();

    React.useEffect(() => {
         setData1({
            cardName: cardName,
            cardNumber: cardNumber,
            expDate: expDate,
            cvv: cvv
         })
    }, [cardName, cardNumber, expDate, cvv]);
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Load Card
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="cardName"
                        label="Name on card"
                        fullWidth
                        autoComplete="cc-name"
                        variant="standard"
                        onChange={(e) => setCardName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="cardNumber"
                        label="Card number"
                        fullWidth
                        autoComplete="cc-number"
                        variant="standard"
                        onChange={(e) => setCardNumber(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="expDate"
                        label="Expiry date  mm/yy"
                        fullWidth
                        autoComplete="cc-exp"
                        variant="standard"
                        onChange={(e) => setExpDate(e.target.value)}

                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="cvv"
                        label="CVV"
                        helperText="Last three digits on signature strip"
                        fullWidth
                        autoComplete="cc-csc"
                        variant="standard"
                        onChange={(e) => setCvv(e.target.value)}

                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox color="secondary" name="saveCard" value="yes" />}
                        label="Remember credit card details for next time"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

