import logo from './logo.svg';
import './App.css';
import HolderInfo from './components/HolderInfo';
import LoadCard from './components/LoadCard';
import {isMobile} from 'react-device-detect';
import Home from './components/Home';
function App() {
  return (
    <div className="App">
      <header className="App-header">
      
      <div >
        <Home/>
      </div>

      </header>
    </div>
  );
}

export default App;
